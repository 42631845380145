import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { UserService } from "@app/core/services/user.service";
import { UtilsService } from "@app/core/services/utils.service";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { filter } from "rxjs/operators";
import { AvailableDistributionChannelsService } from "./core/services/available-distribution-channels.service";
import { CountriesService } from "./core/services/countries.service";
import { IndicativeService } from "./core/services/indicative.service";
import { NotificationsService } from "./core/services/notifications.service";
import { DashboardService } from "./modules/dashboard/dashboard.service";
import { AppsListService } from "./modules/my-apps/apps-list/apps-list.service";
import { RatesByCountryService } from "./modules/my-apps/apps-list/vat-policy/rates-by-country/rates-by-country.service";
import { StatisticsDownloadsChartDataService } from "./modules/statistics/services/downloads/chart-data.service";
import { StatisticsDownloadsTopAppsDataService } from "./modules/statistics/services/downloads/top-apps-data.service";
import { StatisticsDownloadsTopCountriesDataService } from "./modules/statistics/services/downloads/top-countries-data.service";
import { StatisticsDownloadsTopStoresDataService } from "./modules/statistics/services/downloads/top-stores-data.service";
import { StatisticsDownloadsTotalsDataService } from "./modules/statistics/services/downloads/totals-data.service";
import { StatisticsRevenueChartDataService } from "./modules/statistics/services/revenue/chart-data.service";
import { StatisticsRevenueTopAppsDataService } from "./modules/statistics/services/revenue/top-apps-data.service";
import { StatisticsRevenueTopCountriesDataService } from "./modules/statistics/services/revenue/top-countries-data.service";
import { StatisticsRevenueTopStoresDataService } from "./modules/statistics/services/revenue/top-stores-data.service";
import { StatisticsRevenueTotalsDataService } from "./modules/statistics/services/revenue/totals-data.service";
import { StatisticsFiltersService } from "./modules/statistics/services/settings.service";
import { WalletFiltersService } from "./modules/wallet/filters/filters.service";
import { WalletMainService } from "./modules/wallet/main/main.service";
import { WalletTransactionsListService } from "./modules/wallet/transactions-list/transactions-list.service";
import { MobileFiltersService } from "./shared/mobile-filters/mobile-filters.service";

@Component({
    selector: "aptd-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
    automaticallyFetchNotifications: boolean = true;
    intervalRef;

    constructor(
        private translate: TranslateService,
        private router: Router,
        private userService: UserService,
        private notificationsService: NotificationsService,
        private utilsService: UtilsService,
        private indicativeService: IndicativeService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,

        /* SERVICES ARE INCLUDED BELOW FOR PRE-LOADING PURPOSES */
        private appsListService: AppsListService,
        private countriesService: CountriesService,
        private availableDistributionChannelsService: AvailableDistributionChannelsService,
        private mobileFiltersService: MobileFiltersService,

        private dashboardService: DashboardService,

        private statisticsFiltersService: StatisticsFiltersService,
        private statisticsDownloadsTotalsDataService: StatisticsDownloadsTotalsDataService,
        private statisticsDownloadsChartDataService: StatisticsDownloadsChartDataService,
        private statisticsDownloadsTopAppsDataService: StatisticsDownloadsTopAppsDataService,
        private statisticsDownloadsTopStoresDataService: StatisticsDownloadsTopStoresDataService,
        private statisticsDownloadsTopCountriesDataService: StatisticsDownloadsTopCountriesDataService,
        private statisticsRevenueTotalsDataService: StatisticsRevenueTotalsDataService,
        private statisticsRevenueChartDataService: StatisticsRevenueChartDataService,
        private statisticsRevenueTopAppsDataService: StatisticsRevenueTopAppsDataService,
        private statisticsRevenueTopCountriesDataService: StatisticsRevenueTopCountriesDataService,
        private statisticsRevenueTopStoresDataService: StatisticsRevenueTopStoresDataService,

        private appListService: AppsListService,

        private walletMainService: WalletMainService,
        private walletFiltersService: WalletFiltersService,
        private walletTransactionsListService: WalletTransactionsListService,
        private ratesByCountryService: RatesByCountryService,
        /* SERVICES ARE INCLUDED ABOVE FOR PRE-LOADING PURPOSES */

        @Inject(DOCUMENT) private doc: any
    ) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (event.url === "/notifications") {
                clearInterval(this.intervalRef);
                this.intervalRef = null;
                return;
            }
            if (!this.intervalRef) {
                this.loadUserNotifications();
            }
        });
    }

    ngOnInit() {
        this.utilsService.init();

        if (environment.production) {
            this.router.events.subscribe(() => {
                if (typeof (<any>window).Intercom !== "undefined") {
                    (<any>window).Intercom("update");
                }
            });
        }

        this.indicativeService.sendEvent(`developer_console_impression`);
    }

    getDefaultRangeDate() {
        return [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    }

    loadUserNotifications() {
        this.intervalRef = setInterval(() => {
            this.notificationsService.loadLatestNotifications();
        }, this.notificationsService.dropdownRefreshInterval);
    }
}
