import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NotificationDetailsModalComponent } from "@app/shared/notification-details-modal/notification-details-modal.component";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { BehaviorSubject } from "rxjs";
import { APIRequestsService, RequestParameters } from "./api.requests.service";
import { UserService } from "./user.service";
export interface INotificationItem {
    applicationName: string;
    applicationTitle: string;
    versionMd5sum: string;
    versionName: string;
    versionCode: number;
    hasTranslatableShortDescription: boolean;
    hasLongDescription: boolean;
    shortDescription: string;
    longDescription: string;
    code: string;
    id: number;
    clickable: boolean;
    read: boolean;
    time: string;
    type: string;
    url: string;
    operatingSystemName: string;
    subscriptionUid: string;
    subscriptionStartsAt: string;
    subscriptionEndsAt: string;
    subscriptionCurrency: string;
    subscriptionAmount: number;
    withdrawalRequestTransactionId: string;
}
@Injectable({
    providedIn: "root",
})
export class NotificationsService {
    loadingLatestNotifications = new BehaviorSubject<boolean>(false);
    haveUnreadNotifications = new BehaviorSubject<boolean>(false);
    notificationListForDropdown = new BehaviorSubject<any>([]);
    dropdownRefreshInterval: number = 120000;

    constructor(private requestsService: APIRequestsService, private userService: UserService, private router: Router, private dialog: MatDialog, private translateService: TranslateService) {
        this.loadLatestNotifications();
    }

    getNotifications(limit: number, offset: number) {
        const url = `/api/accounts/${this.userService.loggedAccount.value.uid}/notifications?limit=${String(limit)}&offset=${String(offset)}`;

        return this.requestsService.get(url);
    }

    loadLatestNotifications() {
        this.loadingLatestNotifications.next(true);

        this.getNotifications(5, 0).subscribe({
            next: (notifications: any) => {
                this.notificationListForDropdown.next(this.formatNotifications(notifications));
                this.haveUnreadNotifications.next(notifications.some((notification) => notification.status === "NOT_READ" && notification.accountUid === this.userService.loggedAccount.value.uid));
                this.loadingLatestNotifications.next(false);
            },
            error: (error) => {
                this.loadingLatestNotifications.next(false);
            },
        });
    }

    updateNotificationStatus(notificationId: number, status: string) {
        const url = `/api/accounts/${this.userService.loggedAccount.value.uid}/notifications/${notificationId}`;

        const parameters: RequestParameters[] = [];
        parameters.push({ label: "status", data: status });

        this.requestsService.patch(url, parameters).subscribe({
            next: (resp: any) => {},
            error: (error: any) => {},
        });
    }

    markAsRead(notifications) {
        notifications.forEach((notification) => {
            if (!notification.read) {
                this.updateNotificationStatus(notification.id, "READ");
                notification.read = true;
            }
        });
        this.haveUnreadNotifications.next(false);
    }

    formatNotifications(notifications: any) {
        const formattedNotifications = [];
        for (let notification of notifications) {
            if (!notification) {
                continue;
            }

            let _notification: INotificationItem = {
                id: notification.id,
                code: notification.name,
                type: notification.category ? this.parseCategory(notification.category) : "information",
                time: notification.addedTimestamp ? moment(notification.addedTimestamp).fromNow() : "",
                read: notification.status === "READ",
                clickable: this.isNotificationClickable(notification),
                hasTranslatableShortDescription: this.hasTranslatableShortDescription(notification.name),
                hasLongDescription: this.hasLongDescription(notification),
                applicationName: notification.data?.applicationName ? notification.data?.applicationName : "",
                applicationTitle: notification.data?.applicationTitle ? notification.data?.applicationTitle : notification.data?.applicationName ? notification.data?.applicationName : "",
                versionMd5sum: notification.data?.versionMd5sum ? notification.data?.versionMd5sum : "",
                versionName: notification.data?.versionName ? notification.data?.versionName : "",
                versionCode: notification.data?.versionCode ? notification.data?.versionCode : 0,
                shortDescription: notification.data?.shortDescription ? notification.data?.shortDescription : "",
                longDescription: notification.data?.longDescription ? notification.data?.longDescription : "",
                url: notification.data?.url ? notification.data?.url : "",
                operatingSystemName: notification.data?.operatingSystemName ? notification.data?.operatingSystemName : "",
                subscriptionUid: notification.data?.subscriptionUid ? notification.data?.subscriptionUid : "",
                subscriptionStartsAt: notification.data?.subscriptionStartsAt ? moment(new Date(notification.data?.subscriptionStartsAt)).format("LLL") : "",
                subscriptionEndsAt: notification.data?.subscriptionEndsAt ? moment(new Date(notification.data?.subscriptionEndsAt)).format("LLL") : "",
                subscriptionCurrency: notification.data?.subscriptionCurrency ? notification.data?.subscriptionCurrency : "",
                subscriptionAmount: notification.data?.subscriptionAmount ? notification.data?.subscriptionAmount : 0,
                withdrawalRequestTransactionId: notification.data?.withdrawalRequestTransactionId ? notification.data?.withdrawalRequestTransactionId : null,
            };

            formattedNotifications.push(_notification);
        }
        return formattedNotifications;
    }

    parseCategory(category: string) {
        switch (category) {
            case "INFORMATION":
                return "information";
            case "ACTION_REQUIRED":
                return "actionRequired";
            default:
                return "information";
        }
    }

    hasTranslatableShortDescription(code) {
        const translation = this.translateService.instant("notifications." + code + ".short");
        return !!translation && translation !== "notifications." + code + ".short";
    }

    hasLongDescription(notification: any) {
        return !!notification.longDescription || !!this.translateService.instant("notifications." + notification.name + ".long");
    }

    isNotificationClickable(notification: any) {
        return (() => {
            if (this.hasLongDescription(notification)) {
                return true;
            }

            if (notification.name) {
                if (notification.name.includes("ACCOUNT_SUBSCRIPTION")) {
                    return true;
                }
            }

            if (!!notification.data) {
                if (notification.data.url || notification.data.applicationName || notification.data.versionMd5sum) {
                    return true;
                }
                if (notification.data.withdrawalRequestTransactionId) {
                    return true;
                }
            }
            return false;
        })();
    }

    onNotificationClick(notification: INotificationItem, showLongDescription: boolean) {
        this.updateNotificationStatus(notification.id, "READ");
        notification.read = true;

        if (!!notification.hasLongDescription && !showLongDescription) {
            this.dialog.open(NotificationDetailsModalComponent, {
                panelClass: "notifications-details-modal",
                data: notification,
                width: "90dvw",
                maxWidth: "600px",
            });
            return;
        }

        if (notification.url && !showLongDescription) {
            window.open(notification.url, "_blank");
            return;
        }

        if (notification.withdrawalRequestTransactionId) {
            this.router.navigate([`/wallet/transactionDetails`], { queryParams: { id: notification.withdrawalRequestTransactionId } });
            return;
        }

        if (notification.code.includes("MISSING_ACCOUNT_DATA")) {
            this.router.navigate(["/settings/account"]);
            return;
        }

        if (notification.code.includes("ACCOUNT_SUBSCRIPTION")) {
            this.router.navigate(["/settings/account"]);
            return;
        }

        if (notification.applicationName) {
            if (["APP_LIVE", "CERTIFICATION_EMAIL_SENT", "APPLICATION_OWNERSHIP_REQUEST_APPROVED", "APPLICATION_OWNERSHIP_REQUEST_NOT_APPROVED"].includes(notification.code)) {
                this.router.navigate([`/myApps/${notification.applicationName}`]);
                return;
            }

            if (notification.code === "NEW_MARKET_APPLICATION_WITH_BILLING_VERSION_FOUND") {
                this.router.navigate([`/myApps/submission`], { queryParams: { packageName: notification.applicationName } });
                return;
            }

            if (notification.versionMd5sum) {
                this.router.navigate([`/myApps/${notification.applicationName}/${notification.versionMd5sum}`], {
                    queryParams: { operatingSystem: notification.operatingSystemName ? notification.operatingSystemName : "android" },
                });
                return;
            }

            this.router.navigate([`/myApps/${notification.applicationName}`], { queryParams: { operatingSystem: notification.operatingSystemName ? notification.operatingSystemName : "android" } });
            return;
        }

        this.expandNotification(notification, showLongDescription);
    }

    expandNotification(notification: INotificationItem, showLongDescription: boolean) {
        if (!!notification.hasLongDescription && !showLongDescription) {
            this.dialog.open(NotificationDetailsModalComponent, {
                panelClass: "notifications-details-modal",
                data: notification,
                width: "90dvw",
                maxWidth: "600px",
            });
            return;
        }

        if (notification.url && !showLongDescription) {
            window.open(notification.url, "_self");
            return;
        }
    }
}
