import { Injectable } from "@angular/core";
import { APIRequestsService, RequestParameters } from "@app/core/services/api.requests.service";
import { UserService } from "@app/core/services/user.service";
import { BehaviorSubject } from "rxjs";

export interface ParsedOspUrlValidationOutput {
    warnings: number[];
    errors: any[];
}

@Injectable({
    providedIn: "root",
})
export class BillingService {
    hasBilling: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    billingMethod: BehaviorSubject<string> = new BehaviorSubject<string>("sdk");
    showOSPUrlErrors = new BehaviorSubject<boolean>(false);
    walletId = new BehaviorSubject<any>(null);
    publicKey = new BehaviorSubject<any>(null);
    loadingPublicKey: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    ospUrlValidation: BehaviorSubject<ParsedOspUrlValidationOutput> = new BehaviorSubject<ParsedOspUrlValidationOutput>({
        warnings: [],
        errors: [],
    });
    validatingOspURL: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private requestsService: APIRequestsService, private userService: UserService) {
        this.userService.loggedAccount.subscribe({
            next: () => {
                this.loadWalletId();
            },
        });
    }

    reset(packageName, operatingSystem) {
        this.getPublicKey(packageName, operatingSystem);
        this.hasBilling.next(false);
        this.billingMethod.next("sdk");
    }

    loadWalletId() {
        const url = `/api/wallet`;

        return this.requestsService.get(url).subscribe({
            next: (resp: any) => {
                if (resp && resp.address) {
                    this.walletId.next(resp.address);
                }
            },
            error: (error) => {
                this.walletId.next(error.error && error.error.description ? error.error.descrition : JSON.stringify(error.error));
            },
        });
    }

    getPublicKey(packageName: string, operatingSystem: string) {
        this.loadingPublicKey.next(true);
        const url = `/api/applications/${packageName}/publicKey?operatingSystem=${operatingSystem}`;

        return this.requestsService.get(url).subscribe({
            next: (resp: any) => {
                if (resp && resp.length) {
                    this.publicKey.next(resp);
                }
                this.loadingPublicKey.next(false);
            },
            error: (error) => {
                this.publicKey.next(error.error.description ? error.error.descrition : JSON.stringify(error.error));
                this.loadingPublicKey.next(false);
            },
        });
    }

    validateOSPUrl(packageName: string, OSPUrl: string) {
        const url = `/api/utils/applications/validateOneStepPaymentURL`;
        const params: RequestParameters[] = [];

        params.push({ label: "packageName", data: packageName });
        params.push({ label: "url", data: OSPUrl });

        this.ospUrlValidation.next(null);
        this.validatingOspURL.next(true);
        this.requestsService.post(url, params).subscribe({
            next: (response: any) => {
                if (Array.isArray(response)) {
                    this.showOSPUrlErrors.next(true);
                    this.ospUrlValidation.next(this.parseOspUrlValidationOutput(response));
                }
                this.validatingOspURL.next(false);
            },
            error: (error) => {
                this.validatingOspURL.next(false);
            },
        });
    }

    parseOspUrlValidationOutput(response: any[]): ParsedOspUrlValidationOutput {
        const ospUrlValidationView: ParsedOspUrlValidationOutput = {
            warnings: [],
            errors: [],
        };

        response.forEach((errorsPerParameter) => {
            if (errorsPerParameter["warningCodes"] && errorsPerParameter["warningCodes"].length) {
                ospUrlValidationView.warnings.push(errorsPerParameter["warningCodes"]);
            }

            if (errorsPerParameter["errorCodes"] && errorsPerParameter["errorCodes"].length) {
                ospUrlValidationView.errors.push({
                    parameterName: errorsPerParameter["parameterName"],
                    errorCodes: errorsPerParameter["errorCodes"],
                });
            }
        });
        return ospUrlValidationView;
    }

    getFormData(): FormData {
        const formData = new FormData();
        if (this.hasBilling.value) {
            formData.append("billingMethod", this.billingMethod.value === "osp" ? "catappult-osp" : "catappult-sdk");
        }

        return formData;
    }
}
