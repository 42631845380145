import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { APIRequestsService, RequestParameters } from "@app/core/services/api.requests.service";
import { AutocompleteEntry } from "@app/shared/autocomplete/autocomplete.component";
import { BehaviorSubject, ObservableInput, forkJoin } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AppsListService {
    appsStates: any = [
        { label: "myApps2.appList.state.all", value: "all", selected: true },
        {
            label: "myApps2.appList.state.distributed",
            value: "distributed",
            selected: false,
        },
        {
            label: "myApps2.appList.state.undistributed",
            value: "undistributed",
            selected: false,
        },
        {
            label: "myApps2.appList.state.pending",
            value: "pending",
            selected: false,
        },
        { label: "myApps2.appList.state.draft", value: "draft", selected: false },
    ];
    operatingSystems: any = [
        { label: "myApps2.appList.operatingSystem.all", value: "all", selected: true },
        { label: "myApps2.appList.operatingSystem.android", value: "android", selected: false },
        {
            label: "myApps2.appList.operatingSystem.ios",
            value: "ios",
            selected: false,
        },
    ];

    loadingAppsList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    loadingAppsListForAutocompleteData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    loadingKeys: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    appsList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    draftsAppsSize: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    billingPublicKey: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    sha1: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    sha256: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    walletAddress: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    PendingAppsSize: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    distributedAppsSize: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    undistributedAppsSize: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    selectedTab: BehaviorSubject<any> = new BehaviorSubject<any>(this.appsStates[0]);
    selectedOperatingSystem: BehaviorSubject<any> = new BehaviorSubject<any>(this.operatingSystems[0]);
    pendingSubmissions: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    loadingPendingSubmissions: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    autocompleteData: BehaviorSubject<AutocompleteEntry[]> = new BehaviorSubject<AutocompleteEntry[]>([]);

    appsListFilter: BehaviorSubject<String> = new BehaviorSubject<string>("");

    constructor(private requestsService: APIRequestsService, private router: Router) {
        this.getAppsWithApprovedVersionsListForAutocompleteData();
    }

    getAllList(forceUpdate?: boolean) {
        this.loadingAppsList.next(true);
        this.appsList.next(null);

        forkJoin([
            this.requestsService.get(`/api/applications`, this.getDistributedListParameters(forceUpdate)),
            this.requestsService.get(`/api/applications`, this.getUndistributedListParameters(forceUpdate)),
            this.requestsService.get(`/api/applications`, this.getApprovedListParameters(forceUpdate)),
            this.requestsService.get(`/api/applications`, this.getPendingListParameters(forceUpdate)),
            this.requestsService.get(`/api/applications`, this.getDraftsListParameters(forceUpdate)),
            this.requestsService.get(`/api/applications`, this.getIosListParameters(forceUpdate)),
        ]).subscribe({
            next: (res: any) => {
                res[0].forEach((element) => {
                    element.feStatus = "distributed";
                });
                res[1].forEach((element) => {
                    element.feStatus = "undistributed";
                });
                res[2].forEach((element) => {
                    element.feStatus = "pending";
                });
                res[3].forEach((element) => {
                    element.feStatus = "pending";
                });
                res[4].forEach((element) => {
                    element.feStatus = "draft";
                });
                res[5].forEach((element) => {
                    if (element.accountApplication.distributed && element.accountApplication.status === "APPROVED") {
                        element.feStatus = "distributed";
                    }
                    if (element.accountApplication.distributed && element.accountApplication.status !== "APPROVED") {
                        element.feStatus = "pending";
                    }
                    if (!element.accountApplication.distributed && element.accountApplication.status === "APPROVED") {
                        element.feStatus = "undistributed";
                    }
                });

                this.appsList.next(res[0].concat(res[1], res[2], res[3], res[4], res[5]));
                this.loadingAppsList.next(false);
            },
        });
    }
    getAndroidList(forceUpdate?: boolean) {
        this.loadingAppsList.next(true);
        this.appsList.next(null);

        forkJoin([
            this.requestsService.get(`/api/applications`, this.getDistributedListParameters(forceUpdate)),
            this.requestsService.get(`/api/applications`, this.getUndistributedListParameters(forceUpdate)),
            this.requestsService.get(`/api/applications`, this.getApprovedListParameters(forceUpdate)),
            this.requestsService.get(`/api/applications`, this.getPendingListParameters(forceUpdate)),
            this.requestsService.get(`/api/applications`, this.getDraftsListParameters(forceUpdate)),
        ]).subscribe({
            next: (res: any) => {
                res[0].forEach((element) => {
                    element.feStatus = "distributed";
                });
                res[1].forEach((element) => {
                    element.feStatus = "undistributed";
                });
                res[2].forEach((element) => {
                    element.feStatus = "pending";
                });
                res[3].forEach((element) => {
                    element.feStatus = "pending";
                });
                res[4].forEach((element) => {
                    element.feStatus = "draft";
                });

                this.appsList.next(res[0].concat(res[1], res[2], res[3], res[4]));
                this.loadingAppsList.next(false);
            },
        });
    }

    getDistributedList() {
        this.loadingAppsList.next(true);
        this.appsList.next(null);

        this.requestsService.get(`/api/applications`, this.getDistributedListParameters()).subscribe({
            next: (res: any) => {
                res.forEach((element) => {
                    element.feStatus = "distributed";
                });
                this.appsList.next(res);

                this.loadingAppsList.next(false);
            },
        });
    }

    getUndistributedList() {
        this.loadingAppsList.next(true);
        this.appsList.next(null);

        this.requestsService.get(`/api/applications`, this.getUndistributedListParameters()).subscribe({
            next: (res: any) => {
                res.forEach((element) => {
                    element.feStatus = "undistributed";
                });
                this.appsList.next(res);

                this.loadingAppsList.next(false);
            },
        });
    }

    getAppsWithApprovedVersionsListForAutocompleteData() {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "limit", data: "500" });
        parameters.push({ label: "withApprovedVersions", data: "true" });
        parameters.push({ label: "accountApplicationStatus", data: "APPROVED" });

        this.requestsService.get(`/api/applications`, parameters).subscribe({
            next: (res: any) => {
                const autocompleteData = [];
                res.forEach((element) => {
                    autocompleteData.push({
                        id: element?.application?.name,
                        value: element?.application?.name,
                        label: element?.accountApplication?.extras?.title,
                        iconUrl: element?.accountApplication?.extras?.iconUrl,
                    } as AutocompleteEntry);
                });
                autocompleteData.sort((a, b) => {
                    return a?.label < b?.label ? -1 : a?.label > b?.label ? 1 : 0;
                });
                this.autocompleteData.next(autocompleteData);
                this.loadingAppsListForAutocompleteData.next(false);
            },
        });
    }

    getPendingList() {
        this.loadingAppsList.next(true);
        this.appsList.next(null);

        forkJoin([this.requestsService.get(`/api/applications`, this.getApprovedListParameters()), this.requestsService.get(`/api/applications`, this.getPendingListParameters())]).subscribe({
            next: (res: any) => {
                res[0].forEach((element) => {
                    element.feStatus = "pending";
                });
                res[1].forEach((element) => {
                    element.feStatus = "pending";
                });

                this.appsList.next(res[0].concat(res[1]));
                this.loadingAppsList.next(false);
            },
        });
    }

    getDraftsList() {
        this.loadingAppsList.next(true);
        this.appsList.next(null);

        this.requestsService.get(`/api/applications`, this.getDraftsListParameters()).subscribe({
            next: (res: any) => {
                res.forEach((element) => {
                    element.feStatus = "draft";
                });
                this.appsList.next(res);

                this.loadingAppsList.next(false);
            },
        });
    }
    getiOSList() {
        const parameters: RequestParameters[] = [];

        parameters.push({ label: "operatingSystem", data: "ios" });
        parameters.push({ label: "limit", data: "500" });

        this.loadingAppsList.next(true);
        this.appsList.next(null);

        this.requestsService.get(`/api/applications`, parameters).subscribe({
            next: (res: any) => {
                res.forEach((element) => {
                    if (element.accountApplication.distributed && element.accountApplication.status === "APPROVED") {
                        element.feStatus = "distributed";
                    }
                    if (element.accountApplication.distributed && element.accountApplication.status !== "APPROVED") {
                        element.feStatus = "pending";
                    }
                    if (!element.accountApplication.distributed && element.accountApplication.status === "APPROVED") {
                        element.feStatus = "undistributed";
                    }
                });
                this.appsList.next(res);

                this.loadingAppsList.next(false);
            },
        });
    }
    changeTab(tab: any) {
        this.selectedTab.next(tab);
        this.appsStates.forEach((element) => {
            if (element.value === tab.value) {
                element.selected = true;
            } else {
                element.selected = false;
            }
        });

        switch (tab.value) {
            case "distributed": {
                this.getDistributedList();
                break;
            }
            case "undistributed": {
                this.getUndistributedList();
                break;
            }
            case "draft": {
                this.getDraftsList();
                break;
            }
            case "pending": {
                this.getPendingList();
                break;
            }
            case "all": {
                if (this.selectedOperatingSystem.value.value === "ios") {
                    this.getiOSList();
                    break;
                }
                this.getAllList();
                break;
            }
            default: {
                this.getAllList();
                break;
            }
        }
    }

    getSubmissionStatus(appPackageName: string) {
        return this.requestsService.get(`/api/applications/${appPackageName}/submissionStatus?operatingSystem=ios`);
    }

    changeOperatingSystem(tab: any) {
        this.selectedOperatingSystem.next(tab);
        this.operatingSystems.forEach((element) => {
            if (element.value === tab.value) {
                element.selected = true;
            } else {
                element.selected = false;
            }
        });
        this.selectedTab.next(this.appsStates[0]);

        if (tab.value === "ios") {
            this.getiOSList();
            return;
        }
        if (tab.value === "all") {
            this.getAllList();
            return;
        }
        if (tab.value === "android") {
            this.getAndroidList();
            return;
        }
    }

    deleteDraft(packageName: string) {
        this.loadingAppsList.next(true);

        this.requestsService.delete(`/api/applications/${packageName}`).subscribe({
            next: (res: any) => {
                this.changeTab(this.selectedTab?.value);
            },
            error: () => {
                this.changeTab(this.selectedTab?.value);
            },
        });
    }

    getBillingPublicKey(packageName: string) {
        this.billingPublicKey.next(null);
        this.sha1.next(null);
        this.sha256.next(null);
        this.loadingKeys.next(true);

        this.requestsService.get(`/api/applications/${packageName}/apiKeys`).subscribe({
            next: (response: any) => {
                this.billingPublicKey.next(response?.publicKey);
                this.sha1.next(response?.sha1);
                this.sha256.next(response?.sha256);
                this.loadingKeys.next(false);
            },
            error: (error: any) => {
                this.billingPublicKey.next(error.error && error.error.description ? error.error.description : JSON.stringify(error.error));
                this.loadingKeys.next(false);
            },
        });
    }

    getWalletAddress() {
        this.walletAddress.next(null);

        this.requestsService.get(`/api/wallet`).subscribe({
            next: (response: any) => {
                this.walletAddress.next(response.address);
            },
            error: (error: any) => {
                this.walletAddress.next(error.error && error.error.description ? error.error.description : JSON.stringify(error.error));
            },
        });
    }

    checkPendingSubmissions() {
        this.loadingPendingSubmissions.next(true);

        this.requestsService.get(`/api/applications/importedPendingSubmission`).subscribe({
            next: (res: any) => {
                res.forEach((element) => {
                    element.selected = true;
                });
                this.pendingSubmissions.next(res);
                this.loadingPendingSubmissions.next(false);
            },
        });
    }

    addPending() {
        this.loadingPendingSubmissions.next(true);

        const requests: ObservableInput<any>[] = [];

        for (const element of this.pendingSubmissions.value) {
            if (element.selected) {
                const params: RequestParameters[] = [];
                params.push({ label: "name", data: element.name });
                params.push({ label: "addDownloadRequest", data: true });
                requests.push(this.requestsService.post(`/api/applications`, params));
            }
        }

        forkJoin(requests).subscribe({
            next: (res) => {
                this.changeTab(this.appsStates[4]);
                this.router.navigate(["myApps"]);
                this.loadingPendingSubmissions.next(false);
            },
        });
    }
    private getDistributedListParameters(forceUpdate: boolean = false): RequestParameters[] {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "limit", data: "500" });
        parameters.push({ label: "withLiveVersions", data: "true" });
        parameters.push({
            label: "accountApplicationStatus",
            data: "APPROVED",
        });
        if (forceUpdate) {
            parameters.push({ label: "nocache", data: "1" });
        }
        return parameters;
    }

    private getUndistributedListParameters(forceUpdate: boolean = false): RequestParameters[] {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "limit", data: "500" });
        parameters.push({ label: "withLiveVersions", data: "false" });
        parameters.push({ label: "withApprovedVersions", data: "true" });

        parameters.push({
            label: "accountApplicationStatus",
            data: "APPROVED",
        });
        if (forceUpdate) {
            parameters.push({ label: "nocache", data: "1" });
        }
        return parameters;
    }

    private getApprovedListParameters(forceUpdate: boolean = false): RequestParameters[] {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "limit", data: "500" });
        parameters.push({ label: "withVersions", data: "true" });
        parameters.push({ label: "withLiveVersions", data: "false" });

        parameters.push({
            label: "accountApplicationStatus",
            data: "APPROVED",
        });
        parameters.push({ label: "withApprovedVersions", data: "false" });
        if (forceUpdate) {
            parameters.push({ label: "nocache", data: "1" });
        }
        return parameters;
    }

    private getPendingListParameters(forceUpdate: boolean = false): RequestParameters[] {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "limit", data: "500" });
        parameters.push({ label: "withVersions", data: "true" });
        parameters.push({ label: "accountApplicationStatus", data: "PENDING" });

        if (forceUpdate) {
            parameters.push({ label: "nocache", data: "1" });
        }
        return parameters;
    }

    private getDraftsListParameters(forceUpdate: boolean = false): RequestParameters[] {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "limit", data: "1000" });
        parameters.push({ label: "withVersions", data: "false" });

        if (forceUpdate) {
            parameters.push({ label: "nocache", data: "1" });
        }
        return parameters;
    }

    private getIosListParameters(forceUpdate: boolean = false): RequestParameters[] {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "operatingSystem", data: "ios" });

        return parameters;
    }
}
