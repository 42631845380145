<div class="modal-container">
    <img class="close-icon" (click)="closeDialog()" src="/assets/images/closeX.svg" />
    <div class="notification">
        <div class="first-row">
            <div class="avatar">
                <img
                    *ngIf="!notification.applicationName && notification.code !== 'ACCOUNT_SUBSCRIPTION_ACTIVE' && notification.code !== 'WITHDRAW_REQUEST_APPROVED'"
                    class="image white-background"
                    src="/assets/images/catappult-small-logo.png"
                />
                <img
                    *ngIf="!notification.applicationName && (notification.code === 'ACCOUNT_SUBSCRIPTION_ACTIVE' || notification.code === 'WITHDRAW_REQUEST_APPROVED')"
                    class="image white-background"
                    src="/assets/images/green-circle-check.svg"
                />
                <img
                    *ngIf="!!notification.applicationName"
                    class="image white-background"
                    #img
                    (error)="img.src = 'assets/images/appicon-fallback.svg'"
                    [src]="notification.applicationName ? (notification.applicationName | appIcon) : '/assets/images/avatar-placeholder.svg'"
                />
                <img *ngIf="notification.code === 'NEW_MARKET_APPLICATION_WITH_BILLING_VERSION_FOUND'" class="badge" src="/assets/images/update-badge.svg" />
            </div>

            <div class="text-container">
                <p *ngIf="notification.code === 'CUSTOM'" [class.force-white]="!!dashboardMobile" class="xs bold very-low-line-height">
                    {{ notification.shortDescription }}
                </p>
                <p
                    *ngIf="notification.code !== 'CUSTOM'"
                    [innerHTML]="'notifications.' + notification.code + '.title' | translate : { notification: notification }"
                    [class.force-white]="!!dashboardMobile"
                    class="xs bold very-low-line-height"
                ></p>
                <div class="time-and-chip">
                    <div class="bullet"><span *ngIf="!notification.read">•</span></div>
                    <div class="xxxs darkGray">{{ notification.time }}</div>
                    <div *ngIf="!!notification.type" class="chip" [class]="notification.type">
                        <p class="xxxs" translate>{{ "notificationCopies." + notification.type }}</p>
                    </div>
                </div>
            </div>
        </div>
        <p
            *ngIf="notification.hasLongDescription && notification.longDescription && notification.code === 'CUSTOM'"
            class="xxs medium long-description margin-left-but-not-on-mobile"
            [innerHTML]="notification.longDescription"
        ></p>
        <p
            *ngIf="notification.hasLongDescription && !notification.longDescription && notification.code !== 'CUSTOM'"
            [innerHTML]="'notifications.' + notification.code + '.long' | translate : { notification: notification }"
            class="xxs medium long-description margin-left-but-not-on-mobile"
        ></p>

        <app-button
            class="know-more-button margin-left-but-not-on-mobile"
            *ngIf="notification.url"
            [veryVerySmall]="true"
            [blueOnTransparent]="true"
            [label]="'notificationCopies.knowMore' | translate"
            (onClick)="goToURL(notification.url)"
        ></app-button>
    </div>
</div>
