import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-notification-details-modal",
    templateUrl: "./notification-details-modal.component.html",
    styleUrl: "./notification-details-modal.component.scss",
})
export class NotificationDetailsModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public notification: any, private dialogRef: MatDialogRef<NotificationDetailsModalComponent>) {}

    closeDialog() {
        this.dialogRef.close(false);
    }

    goToURL(url: string) {
        window.open(url, "_self");
    }
}
